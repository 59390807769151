module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://workativ.com","noQueryString":true,"noTrailingSlash":true,"exclude":["/conversational-ai-platform/10-major-it-workflow-automation-benefits","/conversational-ai-platform/8-best-it-workflow-automation-practices-to-follow-for-chatbots","/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai","/conversational-ai-platform/get-over-the-pandemic-with-digital-assistants","/conversational-ai-platform/how-ai-powered-chatbots-with-automated-workflows-can-help-digital-workers","/conversational-ai-platform/slack-chatbot-guide","/conversational-ai-platform/4-reasons-to-use-ms-teams-as-a-digital-helpdesk"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MPXH52N","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_IE","url":"https://workativ.com","site_name":"https://workativ.com"},"twitter":{"handle":"@workativ","site":"https://workativ.com","cardType":"summary"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Workativ","short_name":"Workativ","start_url":"/","icon":"src/assets/images/workativ-fevicon.ico","icons":[{"src":"/images/workativ-fevicon.ico","sizes":"72x72","type":"image/ico"}],"crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f4870a2e727274f19001dd7299eb0e3e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"7c8bdb39a58feedea88d1c4ce2343203","enableOnDevMode":true,"pageViews":"all","debug":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
